import {useState} from "react";
import styled from "styled-components";
import ApiCalendar from "react-google-calendar-api";
import apikey from "./config/apiKeys/culture-beat-calendar-306317-cdf1506d34a1.json";

const config = {
    clientId: "114259338905254710957",
    apiKey: "AIzaSyAAesUrLoG8jPcbL3WPgq-dt6kzz_-Ky9c",
    // apiKey: apikey,
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
};

const apiCalendar = new ApiCalendar(config);

const Wrapper = styled.div`
    width: 90%;
    display: inline-block;
    border: 1px solid white;
`;

const Form = styled.form`

`;

const Input = styled.input`
    margin: 20px;
`;

function SubmitForm() {
    return <Form>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <Input type="text"></Input>
        <button type="submit">Submit</button>
    </Form>
}

function Events(apiEvents) {
    return apiEvents.map((event, index) => (
        <p>event</p>
    ));
}

function ManageEvents() {
    const [apiEvents, setApiEvents] = useState([]);

    const signInGoogle = () => {
        //https://console.cloud.google.com/apis/credentials/consent/edit?project=culture-beat-calendar-306317
         apiCalendar.handleAuthClick();
        // setApiEvents(apiCalendar.listEvents({
        //     maxResults: 10,
        //     orderBy: 'updated'
        // }));
        // console.log(apiEvents);
    }

    return (
        <Wrapper>
            <button
                onClick={() => signInGoogle()}
            >
                sign-in Google
            </button>
            <SubmitForm></SubmitForm>
            {/*<Events apiEvents={apiEvents} />*/}
        </Wrapper>
    );
}

export default ManageEvents;
