import ManageEvents from "./ManageEvents";
import styled from "styled-components";
import './App.css';

const Wrapper = styled.div`
    width: 100%;
    height: 500px;
    display: block;
    background: black;
    color: white;
    text-align: center;
`;

function App() {
  return (
    <Wrapper>
      <ManageEvents>

      </ManageEvents>
    </Wrapper>
  );
}

export default App;
